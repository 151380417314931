<template>
  <v-container>
    <h4>Inputs for this type of export:</h4>
    <v-col class="elevation-1"
      v-for="input in inputsInternal" :key="input.prop"
    >
      <v-text-field v-if="input.type === 'fiscalYear'"
        class="tmc-export fiscalYear"
        v-model.number="input.value"
        :label="input.label || 'Fiscal Year'"
        type="number"
      ></v-text-field>
      <ClarionDateControl v-else-if="input.type === 'date'"
        v-model="input.value"
        :isRequired="true"
        :isIsoDate="true"
        :label="input.label || 'Date'"
        classes="tmc-export date"
      />
      <v-row v-else-if="input.type === 'monthAndYear'">
        <v-col cols="2">
          <v-select
            :disabled="isDisableMonthAndYearFields"
            v-model="input.selectedMonth"
            :items="months"
            :label="'Month'"
            class="tmc-export month"
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            :disabled="isDisableMonthAndYearFields"
            v-model.number="input.selectedYear"
            label="Year"
            type="number"
            class="tmc-export year"
          ></v-text-field>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="3">
          <v-checkbox
            class="font-weight-bold"
            v-model="isExportForEntireFy"
            label="Export for entire FY"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-else-if="input.type === 'showFromToDates'">
        <v-col id="tmc-export-from-date">
          <ClarionDateControl
            :isDisabled="isDisableFromToDatesFields"
            v-model="input.fromDate"
            :isRequired="true"
            :isIsoDate="true"
            label="From Date" />
        </v-col>
          <v-col>
            <ClarionDateControl
              :isDisabled="isDisableFromToDatesFields"
              v-model="input.toDate"
              :isRequired="true"
              :isIsoDate="true"
              label="To Date" />
        </v-col>
        <v-col>
          <v-checkbox
            class="font-weight-bold"
            v-model="isExportForEntireFyWithFromToDates"
            label="Export for entire FY"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-text-field v-else-if="input.type === 'constant'"
        classes="tmc-export readonly text-field"
        v-model="input.value"
        :label="input.label || input.prop"
        type="text"
        readonly
      ></v-text-field>
      <v-checkbox v-else-if="input.type === 'optionalCheckBox'"
        class="font-weight-bold"
        v-model="input.value"
        :label="input.label || 'Optional Value'"
        :disabled="input.disabled"
      ></v-checkbox>
      <v-text-field v-else-if="input.type === 'optionalTextField'"
        v-model="input.value"
        :label="input.label || 'Optional Text Field'"
        :disabled="input.disabled"
      ></v-text-field>
      <span v-else>
        Unknown type: "{{input.type}}"
      </span>
    </v-col>
    <v-col>
      <v-btn ref="btnExport"
        @click="exportButton"
        target="_blank">
        Export
      </v-btn>
    </v-col>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ClarionDateControl from '../common/ClarionDateControl.vue';
import { clients } from '../../util/clients';
import {
  destructureDate,
  toFiscalYear,
  toIsoDate,
} from '../../util/shared/tmc-global';
import { checkStringEmptiness } from '../../util/shared/vue-global';

const { backendRest } = clients.direct;

export const parsedPipelineItem = (i) => {
  const result = {
    ...i,
    sourceTemplate: i.sourceTemplate || '',
    inputs: JSON.parse(i.inputs || '[]'),
    pipeline: JSON.parse(i.pipeline || '[]'),
    csvInputs: JSON.parse(i.csvInputs || '[]'),
    fixedWidthTemplate: JSON.parse(i.fixedWidthTemplate || '[]'),
  };
  return result;
};

export default {
  name: 'ExportFilesBetaInputCols',
  components: {
    ClarionDateControl,
  },
  data: () => ({
    inputsInternal: [],
    token: undefined,
    isExportForEntireFy: false,
    isExportForEntireFyWithFromToDates: false,
  }),
  props: {
    program: {
      type: String,
      default: '',
    },
    inputs: {
      type: Array,
      default: () => ([]),
    },
    mongoId: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },
    collectionName: {
      type: String,
      default: '',
    },
    fileHeaders: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    const { jwt } = await this.reAuth();
    this.token = `${jwt}`;
    await this.initializeInputs(this.inputs);
    this.emitInputs();
  },
  computed: {
    months() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    isDisableMonthAndYearFields() {
      return this.isExportForEntireFy;
    },
    isDisableFromToDatesFields() {
      return this.isExportForEntireFyWithFromToDates;
    },
    exportUrl() {
      const paramsArr = [...this.inputsInternal];
      const url = `|BASE_URL|/transmap/export?fileHeaders=${this.fileHeaders}&id=${this.mongoId}&fileName=${this.fileName}&collectionName=${this.collectionName}&`;

      let urlParams;
      const objWithFY = this.searchObjectByType(paramsArr, 'fiscalYear');
      const fiscalYearValue = objWithFY ? objWithFY.value : toFiscalYear(new Date());

      const showFromToDatesObj = this.searchObjectByType(paramsArr, 'showFromToDates');
      const monthAndYearObj = this.searchObjectByType(paramsArr, 'monthAndYear');

      if (showFromToDatesObj) {
        if (this.isExportForEntireFyWithFromToDates) {
          urlParams = `entireFiscalYear=${fiscalYearValue}&`;
        } else {
          const { fromDate } = showFromToDatesObj;
          const { toDate } = showFromToDatesObj;
          urlParams = `fromDate=${fromDate}&toDate=${toDate}&fiscalYear=${fiscalYearValue}&`;
        }
      } else if (monthAndYearObj) {
        if (this.isExportForEntireFy) {
          urlParams = `entireFiscalYear=${fiscalYearValue}&`;
        } else {
          const { selectedMonth } = monthAndYearObj;
          const { selectedYear } = monthAndYearObj;
          urlParams = `selectedMonth=${selectedMonth}&selectedYear=${selectedYear}&fiscalYear=${fiscalYearValue}&`;
        }
      } else {
        urlParams = paramsArr.reduce((a, b) => `${a + b.prop}=${b.value}&`, '');
      }

      let finalUrl = url + urlParams;
      if (!checkStringEmptiness(this.program)) {
        finalUrl = `${finalUrl}program=${this.program}&`;
      }
      const subsUrl = (finalUrl || '')
        .replace('|BASE_URL|', backendRest.defaults.baseURL);
      return `${subsUrl}token=${this.token}`;
    },
  },
  methods: {
    ...mapActions({
      reAuth: 'reAuth',
    }),
    async emitInputs() {
      this.$emit('inputs', this.inputsInternal);
    },
    async initializeInputs(inputs) {
      this.inputsInternal = await Promise.all(inputs
        .map(async (i) => {
          switch (i.type) {
            case 'fiscalYear':
              return {
                ...i,
                value: toFiscalYear(new Date()),
              };
            case 'date':
              return {
                ...i,
                value: toIsoDate(),
              };
            case 'showFromToDates':
              return {
                ...i,
                fromDate: toIsoDate(),
                toDate: toIsoDate(),
              };
            case 'monthAndYear':
              return {
                ...i,
                selectedMonth: destructureDate(new Date()).month,
                selectedYear: destructureDate(new Date()).year,
              };
            default:
              return i;
          }
        }));
    },
    searchObjectByType(arrayOfObjects, searchValue) {
      return arrayOfObjects.find((obj) => obj.type === searchValue);
    },
    exportButton() {
      const url = (this.exportUrl || '').toString();
      if (url && url !== '' && !url.match(/NaN/) && !url.match(/\/$/)) {
        window.open(url, '_blank');
      }
    },
  },
};

</script>
