<template>
  <v-container :key="reloadKey">
    <HandleExportError
      :dialog="dialog"
      :errorMode="errorMode"
      :errors="errors"
      @openCloseDialog="openCloseDialog"
    />
    <v-row class="mt-4">
      <h3>Export Files</h3>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          id="tmc-transform-export-program"
          v-model="programSelect"
          :items="programList"
          label="Select Program"
          item-text="label"
          item-value="programKey"
          @change="filterPipelineByProgram"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="(selectedProgramDetails || {}).subProgram">
      <v-col cols="8">
        <v-select
          id="tmc-transform-export-subprogram"
          v-model="subProgramSelect"
          :items="subProgramList"
          label="Select Sub Program"
          @change="onSubProgramChange"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-select
          id="tmc-transform-export-select"
          v-model="pipeline"
          :items="filteredProfiles"
          label="Type of export"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="pipelineHasInputs">
      <ExportFilesBetaInputCols
        :program="pipeline.program"
        :inputs="pipeline.inputs"
        :mongoId="pipeline._id"
        :collectionName="pipeline.collectionName"
        :fileName="pipeline.fileName"
        :fileHeaders="fileContainsHeaders"
        @inputs="pipeline.inputs = $event" />
    </v-row>
  </v-container>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import ExportFilesBetaInputCols, { parsedPipelineItem } from '../../components/bulk/ExportFilesBetaInputCols.vue';
import HandleExportError from './HandleExportError.vue';
import { transformMapperFieldsExport, pipelineDefaultExport, programListForExport } from '../../util/shared/transform';
import { resetWindow } from '../../util/shared/vue-global';
import { filterForCountySpecificProfiles } from '../../util/shared/county-specific.config';

const initdata = () => ({
  dialog: false,
  errorMode: undefined,
  pipeline: { ...pipelineDefaultExport },
  reloadKey: 0,
  baseCrudKey: 'transform-mapper-list',
  errors: [],
  programSelect: undefined,
  programListItems: [...programListForExport],
  subProgramSelect: undefined,
});

export default {
  name: 'ExportFilesBeta',
  components: {
    ExportFilesBetaInputCols,
    HandleExportError,
  },
  data: () => initdata(),
  computed: {
    ...mapGetters('base/crud', [
      'items',
    ]),
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
      globalSettingItem: (state) => state.SystemConfig.globalSettingItem || {},
    }),
    subProgramList() {
      const subPrograms = (this.pipelines || []).map((p) => p.value.subProgram);
      const filteredItems = [...new Set(subPrograms.filter((p) => p !== null))];
      return filteredItems;
    },
    pipelines() {
      return (this.items(this.baseCrudKey) || [])
        .map((i) => parsedPipelineItem(i))
        .map((i) => ({
          text: i.name,
          value: i,
        }));
    },
    filteredProfiles() {
      let profiles = [...this.pipelines];
      if (this.selectedProgramDetails.subProgram) {
        const profilesArr = profiles.filter(
          (p) => p.value.subProgram === this.subProgramSelect,
        );
        profiles = profilesArr.sort((a, b) => a.text.localeCompare(b.text, 'en', { numeric: true }));
      }
      const { countyName } = this.globalSettingItem;
      const profilesAfterFilter = profiles.filter(
        (p) => filterForCountySpecificProfiles(p, countyName),
      );
      return profilesAfterFilter;
    },
    pipelineHasInputs() {
      return (this.pipeline.inputs || []).length > 0;
    },
    selectedProgramDetails() {
      return this.programList.find((p) => p.programKey === this.programSelect) || {};
    },
    fileContainsHeaders() {
      if (this.pipeline.source !== 'gql-headerless') {
        return true;
      }
      return false;
    },
    programList() {
      if (!(this.warSettingItem && this.warSettingItem.payrollRegisterProgram)) {
        const newProgramList = this.programListItems.filter((p) => (p.programKey !== 'PAYROLL'));
        return newProgramList;
      }
      return programListForExport;
    },
  },
  created() {
    this.loadConfig({ requiredScope: 'warconfig' });
  },
  methods: {
    ...mapActions('SystemConfig', ['loadConfig']),
    ...mapActions('config', [
      'loadTransformMappers',
    ]),
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    openCloseDialog(value) {
      this.dialog = value;
    },
    filterPipelineByProgram() {
      this.loadTransformMappers({
        criteria: {
          type: 'export',
          program: this.programSelect,
        },
        fields: { ...transformMapperFieldsExport },
        baseCrudKey: this.baseCrudKey,
      });
      resetWindow(this, initdata(), { programSelect: this.programSelect });
    },
    onSubProgramChange() {
      this.pipeline = {};
    },
  },
};
</script>
