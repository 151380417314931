<template>
  <v-dialog
    v-model="openCloseDialog"
    persistent
    max-width="60vw"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Errors while exporting data</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div v-if="isErrorWithHeader">
            <v-data-table
              :headers="headers"
              :items="errors"
              class="elevation-1"
              :hide-default-footer="true"
            ></v-data-table>
          </div>
          <div v-else class="blue lighten-5 rounded-lg pa-6">
            {{ errors }}
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="openCloseDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const initdata = () => ({
  headers: [
    { text: 'Entity', value: 'errorKey' },
    { text: 'Error Data', value: 'data' },
    { text: 'Error message', value: 'message' },
  ],
});

export default {
  name: 'HandleExportError',
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    errorMode: {
      type: String,
      default: () => '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: () => initdata(),
  computed: {
    openCloseDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('openCloseDialog', value);
      },
    },
    isErrorWithHeader() {
      return this.errorMode === 'errorWithHeader';
    },
  },
};
</script>
