export const programList = [
  { label: 'Official Depository', programKey: 'OD', subProgram: true },
  { label: 'Warrants & Appropriations', programKey: 'WAR', subProgram: true },
  { label: 'Payroll Register', programKey: 'PAYROLL', subProgram: true },
];

export const programListImportAndMapper = [
  ...programList,
  { label: 'Miscellaneous Receipt', programKey: 'MISC' },
  { label: 'Mortgage Tax', programKey: 'MTG' },
  { label: 'General Ledger', programKey: 'GL' },
  { label: 'Shared Program', programKey: 'SHARED' },
];

export const programListForExport = [
  ...programList,
  { label: 'Miscellaneous Receipt', programKey: 'MISC', subProgram: true },
  { label: 'Mortgage Tax', programKey: 'MTG', subProgram: true },
];

export const programListForImport = [
  ...programListImportAndMapper,
];

export const programListForMappper = [
  ...programListImportAndMapper,
];

export const transformMapperFields = {
  _id: '',
  name: '',
  source: '',
  inputs: [],
  pipeline: [],
  fixedWidthTemplate: [],
  destination: '',
  reportTemplate: '',
  createDependency: false,
  backendToBackend: false,
  showJsonMapper: false,
  sourceTemplate: '', // for gql query
  program: '',
  subProgram: '',
  countySpecific: '',
  stringFieldNames: [],
  customConfig: {},
  batchSize: null,
};

export const transformMapperFieldsImport = {
  ...transformMapperFields,
};

export const transformMapperFieldsExport = {
  ...transformMapperFields,
  fileName: '',
  collectionName: '',
};

export const transformMapperListFields = {
  ...transformMapperFields,
  type: '',
  fileName: '',
  collectionName: '',
  sourceTemplate: '',
  csvInputs: [],
  jsonMapper: [],
  previewData: '',
};

export const pipelineDefault = {
  _id: '',
  name: '',
  source: '',
  inputs: [],
  pipeline: [],
  fixedWidthTemplate: [],
  destination: '',
  reportTemplate: '',
};

export const pipelineDefaultImport = {
  ...pipelineDefault,
  createDependency: false,
  backendToBackend: false,
  showJsonMapper: false,
};

export const pipelineDefaultExport = {
  ...pipelineDefault,
};
